body {
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}
html {
  height: 100vh;
  background-color: #000000;
}
#root {
  /* height: 100vh; */
}
.vjs-big-play-button {
  display: none !important;
}
